<template>
  <!--企业优势-->
  <div style="width: 100%">
    <div class="advantageContainer">
      <page-header-image />
      <div style="height: 30px"></div>
      <advantage-story />
    </div>
    <div class="livesContainer">
      <div class="livesItemList">
        <div class="livesItem" v-for="item in text" :key="item">
          <img class="lImg" :src="item.img" alt="" />
          <p class="lTest">{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FloatBox from "../components/floatBox.vue";
import PageHeaderImage from "../components/PageHeaderImage.vue";
import AdvantageStory from "./Home/advantageStory.vue";

export default {
  components: {
    FloatBox,
    PageHeaderImage,
    AdvantageStory,
  },
  data() {
    return {
      text: [
        { title: "全自动化", img: require("@/static/img/advantage1.jpg") },
        { title: "高精密", img: require("@/static/img/advantage2.jpg") },
        { title: "装配车间", img: require("@/static/img/advantage3.jpg") },
        { title: "包装车间", img: require("@/static/img/advantage4.jpg") },
      ],
    };
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.advantageContainer {
  width: 100%;
  margin-bottom: 50px;
}

.advantageList {
  position: relative;
  margin: auto;
  margin-top: 20px;
  /* display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  width: 90%;
}

.advantageItem {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60fr 5fr 5fr;
  list-style: none;
  width: 100%;
  margin: 5%;
}

.advantageItem > p {
  font-family: Pingfang SC;
  font-style: normal;
  font-weight: normal;
  /* identical to box height */
  text-align: center;
  color: #0e0e0e;
}

.advantageItem > img {
  /* position: absolute;
    top: 50%;
    left: 50%; */
  margin: auto;
  /* transform: translate(-50%, -50%); */
  width: 100%;
  max-width: 465px;
  padding-bottom: 20px;
}

.advantageItem > .sTC {
  width: 100%;
  display: block;
  font-size: 26px;
  padding-bottom: 10px;
}

.advantageItem > .sTE {
  width: 100%;
  display: block;
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .advantageContainer {
    margin-bottom: 20px;
  }

  .advantageItem > .sTC {
    width: 100%;
    display: block;
    font-size: 18px;
    padding-bottom: 10px;
  }
  .advantageItem > .sTE {
    width: 100%;
    display: block;
    font-size: 14px;
  }
}

.livesContainer {
  width: 100%;
  height: 600px;
  margin-bottom: 50px;
}

.livesItemList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 41px;
  grid-row-gap: 21.5px;
  width: 85%;
  position: relative;
  top: 25px;
  margin-left: 7.5%;
}

.livesItem {
  position: relative;
  width: 100%;
}

.livesItem > .lImg {
  width: 100%;
  height: 165px;
  z-index: -1;
}

.livesItem > .lTest {
  text-align: center;
  line-height: 165px;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: PingFang SC;
  font-style: normal;
  font-size: 32px;
  /* identical to box height */
  color: #ffffff;
  z-index: 1;
  background: rgba(4, 29, 114, 0.2);
  margin: 0;
}

@media screen and (max-width: 800px) {
  .livesContainer {
    margin-bottom: 0px;
    height: 530px;
  }

  .livesItemList {
    grid-column-gap: 20.5px;
    grid-row-gap: 15.5px;
  }

  .livesItem > .lTest {
    font-size: 24px;
  }
}

@media screen and (min-width: 1500px) {
  .livesContainer {
    height: 1000px;
  }

  .livesItem > .lImg {
    width: 100%;
    height: 300px;
    z-index: -1;
  }

  .livesItem > .lTest {
    line-height: 304px;
  }
}
</style>
