<template>
  <!--企业优势-->
  <div class="advantageContainer">
    <index-title-text :text="advantageText" :link="link" />
    <ul class="advantageList">
      <li class="advantageItem" @click="toDetail(0)">
        <img class="aImg1" src="../../static/img/advantage1.png" alt="" />
        <p class="sTC">精益小单元制造</p>
        <p class="sTE">Lean small unit manufacturing</p>
      </li>
      <li class="advantageItem" @click="toDetail(1)">
        <img class="aImg2" src="../../static/img/advantage2.png" alt="" />
        <p class="sTC">高效的管理团队</p>
        <p class="sTE">Efficient management team</p>
      </li>
      <li class="advantageItem" @click="toDetail(2)">
        <img class="aImg3" src="../../static/img/advantage3.png" alt="" />
        <p class="sTC">高精密的生产设备</p>
        <p class="sTE">High precision production equipment</p>
      </li>
    </ul>
  </div>
</template>
<script>
import IndexTitleText from "../../components/IndexTitleText.vue";

export default {
  components: { IndexTitleText },
  data() {
    return {
      advantageText: "企业优势",
      link: "/advantage",
    };
  },
  methods: {
    toDetail(index) {
      this.$router.push({ name: "AdvantageDetail", params: { index: index } });
      /* this.$router.push('/advantage/details'); */
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.advantageContainer {
  width: 100%;
  margin-bottom: 50px;
}

.advantageList {
  position: relative;
  margin: auto;
  margin-top: 20px;
  /* display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  width: 90%;
}

.advantageItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  list-style: none;
  width: 100%;
  height: 100%;
}

.advantageItem > p {
  font-family: Pingfang SC;
  font-style: normal;
  font-weight: normal;
  /* identical to box height */
  text-align: center;
  color: #0e0e0e;
}

.advantageItem > img {
  /* position: absolute;
    top: 50%;
    left: 50%; */
  margin: auto;
  margin-bottom: 20px;
  margin-top: 0;
  /* transform: translate(-50%, -50%); */
  width: 100%;
  max-width: 465px;
  /*     padding-bottom: 20px; */
}

.advantageItem > .sTC {
  width: 100%;
  display: block;
  font-size: 26px;
  padding-bottom: 10px;
}

.advantageItem > .sTE {
  width: 100%;
  display: block;
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .advantageContainer {
    margin-bottom: 20px;
  }

  .advantageItem > .sTC {
    width: 100%;
    display: block;
    font-size: 18px;
    padding-bottom: 10px;
  }
  .advantageItem > .sTE {
    width: 100%;
    display: block;
    font-size: 14px;
  }
}
</style>
